import { ThemeOptions, createMuiTheme } from "@material-ui/core/styles";

const defaultOptions: ThemeOptions = {
    typography: {
        fontSize: 8,
        fontFamily: "Verdana",
        htmlFontSize: 10,
    },
};

const theme = createMuiTheme(defaultOptions);
theme.overrides = {
    MuiIconButton: {
        root: {
            //color: "#62269E",
            padding: 0,
        }
    },
    MuiInputBase: {
        root: {
            maxWidth: "13.2rem",
        }
    },
    MuiPickersToolbar: {
        toolbar: {
            backgroundColor: "#62269E",
        }
    },
    MuiPickersDay: {
        daySelected: {
            backgroundColor: "#62269E",
            "&:hover": {
                backgroundColor: "#62269E",
            }
        }
    },
    MuiTooltip: {
        tooltip: {
            fontSize: ".8rem",
        }
    }
} as any;

export { theme };
