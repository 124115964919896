import { Card, Link, Typography, withStyles } from "@material-ui/core"
import React from "react"
import BigNumber from 'bignumber.js';


import './Results.scss';

interface ResultsProps {
    costOfService: string;
    monthsPurchased: BigNumber;
};

const StyledCard = withStyles({
    root: {
        margin: "2.0rem",
        borderRadius: "0",
        border: "2px solid #2074ae",
    }
})(Card);

const Title = withStyles({
    root: {
        padding: "1.0rem 0.0rem",
        color: "#2074ae",
        fontWeight: "bold",
        fontSize: "20px"
    }
})(Typography);

const Note = withStyles({
    root: {
        padding: "1.0rem 0.0rem",
        margin: "0.0rem 5.0rem",
        color: "#2074ae",
        fontWeight: "normal",
        fontSize: "16px"
    }
})(Typography);

const Cost = withStyles({
    root: {
        margin: "0rem 3.5rem",
        backgroundColor: "#2074ae",
        color: "white",
        fontWeight: "bold",
        fontSize: "30px"
    }
})(Typography);

const Pdf = withStyles({
    root: {
        color: "#2074ae",
    }
})(Link);

const Results: React.FC<ResultsProps> = ({costOfService, monthsPurchased}) => {

    return (
        <StyledCard variant="outlined" >
            <Title align="center">Estimated Cost of Service Purchase</Title>
            <Cost align="center">{costOfService}</Cost>
            <Note align="center">
                <Pdf href="./AuroraPolice_MBS_Purchase.pdf" target="_blank" rel="noopener noreferrer" >
                    Calculating a monthly benefit from your service purchase
                </Pdf>
            </Note>
        </StyledCard>
    )};

export { Results };