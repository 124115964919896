import React from "react";
import { AppBar, Toolbar, Typography, makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        backgroundColor: "#ff6b00",
        borderWidth: "0 0 .25rem 0",
        borderStyle: "solid",
        borderColor: "#62269E",
        background: "url('./Banner009.jpg') no-repeat",
    },
});

const Header: React.FC = () => {
    const styles = useStyles();
    return (
        <AppBar position="static" className={styles.root}>
            <Toolbar style={{fontWeight: "bold", marginTop: "3rem" }}>
                <Typography variant="h5"  >
                    <Box fontSize="1.9rem" fontWeight="fontWeightBold" style={{opacity: 1}}>
                    Purchase of Service Cost Estimator
                    </Box>
                </Typography>
            </Toolbar>
        </AppBar>)
};

export { Header };