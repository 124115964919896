import React from 'react';
import BigNumber from 'bignumber.js';
import Typography from '@material-ui/core/Typography';
import MomentUtils from "@date-io/moment";
import { Paper, TextField, Select, MenuItem, Grid, Box, Tooltip } from '@material-ui/core';
import moment from "moment";
import {
  MuiPickersUtilsProvider, KeyboardDatePicker,
} from '@material-ui/pickers';

import { Calculator } from './engine/calculator';

import './App.scss';
import { Header } from './Header';
import { FormLabel } from './FormLabel';
import { Footer } from './Footer';
import { Results } from './Results';
import { withStyles } from '@material-ui/core';
import { NumberFormatCustom } from './NumberFormat';

const formLabelGridProps = {
  item: true,
  md: 8,
  xs: 12,
  style: { margin: "auto" }
} as any;

const StyledTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderRadius: "0",
      borderColor: '#Ff6b00',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: "0",
        borderColor: '#Ff6b00',
      },
      '& .MuiOutlinedInput-input': {
        padding: "0.5rem",
      },
      '&:hover fieldset': {
        borderRadius: "0",
        borderColor: '#Ff6b00',
      },
      '&.Mui-focused fieldset': {
        borderRadius: "0",
        borderColor: '#Ff6b00',
      },
    },
  },
})(TextField);

const DisabledTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderRadius: "0",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: "0",
      },
      '& .MuiOutlinedInput-input': {
        padding: "0.5rem",
      },
      '&:hover fieldset': {
        borderRadius: "0",
      },
      '&.Mui-focused fieldset': {
        borderRadius: "0",
      },
    }
  },
})(TextField);

const StyledDatePicker = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderRadius: "0",
      borderColor: '#Ff6b00',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: "0",
        borderColor: '#Ff6b00',
      },
      '& .MuiOutlinedInput-input': {
        padding: "0.5rem",
      },
      '&:hover fieldset': {
        borderRadius: "0",
        borderColor: '#Ff6b00',
      },
      '&.Mui-focused fieldset': {
        borderRadius: "0",
        borderColor: '#Ff6b00',
      },
      '&.MuiIconButton-root': {        
        color: '#Ff6b00',
        padding: "0",
      },   
    },
  }
})(KeyboardDatePicker);

const StyledSelect = withStyles({
  root: {
    padding: "0.5rem",
    '.MuiInputBase-root &': {
      '& ~ fieldset': {
        borderRadius: "0",
        borderColor: '#Ff6b00'
      }
    },
    '.MuiInputBase-root:hover &': {
      '& ~ fieldset': {
        borderColor: '#Ff6b00'
      },
    },
    '.MuiInputBase-root.Mui-focused &': {
      '& ~ fieldset': {
        borderColor: '#Ff6b00'
      },
    }
  }
})(Select);

const SubText = withStyles({
  root: {
    paddingRight: "1.0rem",
    fontWeight: "normal",
  }
})(Typography);

const minDateDob = moment().subtract(79, 'years');
const maxDateDob = moment().subtract(21, 'years');

const minDatePlanEntry = moment(new Date(2022, 0, 1));

function App() {
  const [dateOfBirth, setDateOfBirth] = React.useState<moment.Moment>(maxDateDob);

  const [dateEffective, setDateEffective] = React.useState<moment.Moment>(minDatePlanEntry);
  const [dateOfPlanEntry, setDateOfPlanEntry] = React.useState<moment.Moment>(minDatePlanEntry);
  const [dateOfPurchaseOfService, setDateOfPurchaseOfService] = React.useState<moment.Moment>(moment(null));
    
  const [salary, setSalary] = React.useState<string>("0");
  const [mos, setMos] = React.useState<BigNumber>(new BigNumber(0)); 

  let dob = dateOfBirth;
  if (!dob?.isValid()) {
    dob = maxDateDob;
  } else if (dob.isAfter(maxDateDob)) {
    dob = maxDateDob;
  } else if (dob.isBefore(minDateDob)) {
    dob = minDateDob;
  }

  const calculator = new Calculator({
    dateOfBirth: dob,
    dateEffective,
    dateOfPlanEntry,
    dateOfPurchaseOfService,
    salary: new BigNumber(salary ?? 0),
    monthsOfPurchasedService: (mos.isNaN() ? new BigNumber(0) : mos)
  });

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })

  const dateFormats = {
    system: String('YYYY-MM-DD'),
    enUS: String('MM/DD/YYYY')
  }

  // round to the nearest 1000
  const costOfService = calculator.servicePurchase.isNaN() ? 0 : calculator.servicePurchase.dividedBy(1000).decimalPlaces(0).times(1000).toNumber(); 

  const updateDateOfPlanEntry = (dateOfEntry: moment.Moment) : void => {
    setDateOfPlanEntry(dateOfEntry);
    setDateOfPurchaseOfService(moment(null));
  }

  return (
    <div className="App">
      <Header />
      <Paper elevation={0} style={{ padding: "2rem", paddingBottom: "20rem"}}>
        <Box className="appTitle">Retirement Plan of the Police Department of the City of Aurora (DB Plan)</Box>
        <Box>To estimate the cost to purchase service, enter your information in the <span style={{ color: "#Ff6b00", fontWeight: "bold" }}>orange cells</span>:</Box>

        <Grid container spacing={3} style={{ paddingTop: "2rem"}}>
          <Grid container item spacing={3} xs={12} md={6} style={{ paddingTop: "2rem" }}>

            <Grid container item xs={12}>
              <Grid {...formLabelGridProps}>
                <FormLabel label="Your date of birth (mm/dd/yyyy)" />
              </Grid>
              <Grid item md={4} xs={12}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                  <StyledDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    format={dateFormats.enUS}
                    minDate={minDateDob}
                    maxDate={maxDateDob}
                    minDateMessage={`Date must be after ${minDateDob.format(dateFormats.enUS)}`}
                    maxDateMessage={`Date must be before ${maxDateDob.format(dateFormats.enUS)}`}
                    open={false}
                    value={dateOfBirth}
                    onChange={date => setDateOfBirth(date as moment.Moment)}
                    keyboardIcon={<></>}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid {...formLabelGridProps}>
                <FormLabel label="DB Plan effective date" />
              </Grid>
              <Grid item md={4} xs={12}>
                <DisabledTextField variant="outlined" disabled value={`${dateEffective.format(dateFormats.enUS)}`} />
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid {...formLabelGridProps}>
                <Box fontSize="14px" fontWeight="fontWeightBold">
                  Date you&nbsp;
                  <Tooltip disableFocusListener disableTouchListener title={<>If you are employed by the Aurora Police Department on Jan. 1, 2022, you will have until June 30, 2023 to elect to become a member in the Plan. New employees hired or former employees rehired after Jan. 1, 2022 will have 36 months from their hire/rehire date to make their decision.</>}>
                    <span style={{textDecoration: "underline"}}>opt-in</span>
                  </Tooltip> to the DB plan
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                  <StyledDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    format={dateFormats.enUS}
                    minDate={minDatePlanEntry}
                    minDateMessage={`Date must be after ${minDatePlanEntry.format(dateFormats.enUS)}`}
                    open={false}
                    value={dateOfPlanEntry}
                    onChange={date => updateDateOfPlanEntry(date as moment.Moment)}
                    keyboardIcon={<></>}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid {...formLabelGridProps}>
                <Box fontSize="14px" fontWeight="fontWeightBold">
                  Date you&nbsp;
                  <Tooltip disableFocusListener disableTouchListener title={<>Once you are a vested member in the Plan and accrue one year of credited service, you are eligible to purchase credited service provided you do so within two years of your first eligibility to purchase service credit.</>}>
                    <span style={{textDecoration: "underline"}}>purchase service</span>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledSelect
                  fullWidth
                  variant="outlined"
                  value={dateOfPurchaseOfService.format(dateFormats.system)}
                  defaultValue={calculator.purchaseDateOptions[0]}
                  onChange={e => setDateOfPurchaseOfService(moment(e.target.value as string))}
                >
                  {calculator.purchaseDateOptions.map((option, index) => {
                    if (!dateOfPurchaseOfService.isValid() && index === 0){
                      setDateOfPurchaseOfService(option);
                    };
                    return (
                      <MenuItem key={index} value={option.format(dateFormats.system)}>{option.format(dateFormats.enUS)}</MenuItem>
                    );
                  })}
                </StyledSelect>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid {...formLabelGridProps}>
                <Box fontSize="14px" fontWeight="fontWeightBold">
                  Your estimated annual&nbsp;
                  <Tooltip disableFocusListener disableTouchListener title={<>Annual base salary is the total base salary in the Employer's Compensation Ordinance and/or pertinent City Council resolutions for the rank and grade held by the employee and paid to the employee including other compensation detailed in the Employee Manual and longevity pay. It does <span style={{fontStyle: "italic"}}>not</span> include bonuses, commissions, overtime pay, holiday pay, other taxable income and other forms of extra pay.</>}>
                    <span style={{textDecoration: "underline"}}>base salary</span>
                  </Tooltip>&nbsp;
                  <span className={!dateOfPurchaseOfService.isValid() ? 'hidden' : ''}>on {dateOfPurchaseOfService.format((dateFormats.enUS))}</span>
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextField
                  variant="outlined"
                  value={salary ?? ""}
                  onChange={e => setSalary(e.target.value)}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid {...formLabelGridProps}>
                <FormLabel label="Months of DB Plan service you wish to purchase" />
                <SubText>
                  You may purchase up to all months of full-time service in APD. In addition, you may also purchase up to 60 months of&nbsp;
                  <Tooltip disableFocusListener disableTouchListener title="Qualified employment is full-time employment as a law enforcement officer with any US federal, state or local law enforcement agency, US military service (excluding USERRA service) and any service with the US Public Health Service Commissioned Corp (excluding USERRA service), provided  such service does not result in a benefit under another plan (other than Social Security).">
                    <span style={{textDecoration: "underline"}}>qualified employment</span>
                  </Tooltip>.
                </SubText>
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextField
                  variant="outlined"
                  type="number"
                  value={mos}
                  onChange={e => setMos(new BigNumber(e.target.value))}
                />
                {mos.isGreaterThan(0) && <SubText style={{ color: "#ff6b00" }}>({mos.dividedBy(12).decimalPlaces(3).toString()} years)</SubText>}
              </Grid>
            </Grid>

          </Grid>

          <Grid container item xs={12} md={6} justify="center" alignItems="center">
            <Grid item>
              <Results costOfService={formatter.format(costOfService)} monthsPurchased={(mos.isNaN() ? new BigNumber(0) : mos)} />
            </Grid>
          </Grid>

        </Grid>
      </Paper>

      <Footer dateOfServicePurchase={dateOfPurchaseOfService.isValid() ? dateOfPurchaseOfService.format(dateFormats.enUS) : ''} />
    </div>
  );
}

export default App;