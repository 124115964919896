import NumberFormat from 'react-number-format';
import React from 'react';

interface NumberFormatCustomProps {
    inputRef: any,
    name: any,
    onChange: any,
}

const NumberFormatCustom: React.FC<NumberFormatCustomProps> = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="$"
        />
    );
}

export { NumberFormatCustom };