import { BigNumber } from 'bignumber.js';
import { Decimal } from 'decimal.js';
import moment from "moment";

import * as factor from "./factor";

export interface Inputs {
    dateOfBirth: moment.Moment;              //C5
    dateEffective: moment.Moment;            //C6
    dateOfPlanEntry: moment.Moment;          //C7
    dateOfPurchaseOfService: moment.Moment;  //C8
    salary: BigNumber;                       //C9
    monthsOfPurchasedService: BigNumber;     //C10
}

export interface OtherInputs {
    benefitsMultiplier: BigNumber;          // C11
    discountRate: BigNumber;                // C12
    cola: BigNumber;                        // C13
    salaryProjection: BigNumber;            // C14
}

export class Calculator {
    inputs: Inputs;
    otherInputs: OtherInputs = {
        benefitsMultiplier: new BigNumber(.02),
        discountRate: new BigNumber(.06),
        cola: new BigNumber(0),
        salaryProjection: new BigNumber(.035),
    }

    constructor(inputs: Inputs) {
        this.inputs = inputs;
    }

    get ageActual(): BigNumber {    //G5
        return new BigNumber(moment(this.inputs.dateOfPurchaseOfService).diff(this.inputs.dateOfBirth, "years", true));
    }

    get ageYears(): BigNumber {     //E5
        return this.ageActual.integerValue(BigNumber.ROUND_DOWN);
    }

    get ageMonths(): BigNumber {    //F5
        return this.ageActual.minus(this.ageYears).times(12).integerValue(BigNumber.ROUND_DOWN);
    }

    get age(): BigNumber {         //C5
        return this.ageYears.plus(this.ageMonths.dividedBy(12));
    }

    get yearsOfPurchasedService(): BigNumber {    //C7
        return this.inputs.monthsOfPurchasedService.dividedBy(12);
    }

    get salary(): BigNumber {       //C6
        return this.inputs.salary;
    }

    get dbServiceAtPurchaseDate(): BigNumber {        //C8
        return new BigNumber(moment(this.inputs.dateOfPurchaseOfService).diff(this.inputs.dateOfPlanEntry, "years", true));
    }

    get unreducedRetirementAge(): BigNumber {        //C18
        return BigNumber.min(
            BigNumber.max(65, this.age.minus(this.yearsOfPurchasedService).minus(this.dbServiceAtPurchaseDate).plus(5)),
            BigNumber.max(50, this.age.plus(new BigNumber(80).minus(this.age).minus(this.yearsOfPurchasedService).minus(this.dbServiceAtPurchaseDate).dividedBy(2)))
        );
    }

    get uraH18(): BigNumber {
        return this.uraM18.integerValue(BigNumber.ROUND_DOWN);
    }

    get uraI18(): BigNumber {
        return this.uraM18.minus(this.uraM18.integerValue(BigNumber.ROUND_DOWN)).times(12);
    }

    get uraJ18(): BigNumber {
        return this.unreducedRetirementAge.minus(this.uraK18);
    }

    get uraK18(): BigNumber {
        return this.unreducedRetirementAge.integerValue(BigNumber.ROUND_DOWN);
    }

    get uraL18(): BigNumber {
        const temp = this.uraJ18.times(12);
        if (temp.integerValue(BigNumber.ROUND_DOWN).isEqualTo(temp.decimalPlaces(10))) {
            return temp.decimalPlaces(10);
        } else {
            return temp.integerValue(BigNumber.ROUND_DOWN).plus(1);
        }
    }

    get uraM18(): BigNumber {
        return this.uraK18.plus(this.uraL18.dividedBy(12));
    }

    get pvFactor(): BigNumber {     //C19
        if (this.unreducedRetirementAge.isLessThanOrEqualTo(this.age)) {
            return factor.getImmediateFactor(this.ageMonths.toNumber(), this.ageYears.toNumber()).decimalPlaces(4);
        } else {
            return factor.getImmediateFactor(this.uraI18.toNumber(), this.uraH18.toNumber())
                .times(
                    factor.getDeferredFactor(this.uraI18.toNumber(), this.uraH18.toNumber())
                )
                .dividedBy(
                    factor.getDeferredFactor(this.ageMonths.toNumber(), this.ageYears.toNumber())
                )
                .decimalPlaces(4);
        }
    }

    get salaryFactor(): BigNumber {    //C20
        return this.power(this.otherInputs.salaryProjection.plus(1), BigNumber.max(0, this.unreducedRetirementAge.minus(this.age).minus(1))).plus(
            this.power(this.otherInputs.salaryProjection.plus(1), BigNumber.max(0, this.unreducedRetirementAge.minus(this.age).minus(2)))
        ).plus(
            this.power(this.otherInputs.salaryProjection.plus(1), BigNumber.max(0, this.unreducedRetirementAge.minus(this.age).minus(3)))
        ).dividedBy(3).decimalPlaces(4);
    }

    get servicePurchase(): BigNumber {  //C21
        return this.otherInputs.benefitsMultiplier.times(this.salary).times(this.salaryFactor).times(this.yearsOfPurchasedService).times(this.pvFactor);
    }

    get asAMultiplierOfSalary(): BigNumber {
        return this.servicePurchase.dividedBy(this.salary);
    }

    get nominalRetirementDate(): moment.Moment {
        return moment(new Date(
            this.uraH18.plus(this.inputs.dateOfBirth.year()).toNumber(),
            this.uraI18.plus(this.inputs.dateOfBirth.month()).plus(this.inputs.dateOfBirth.date() === 1 ? 0: 1).toNumber(),
            1
        ));
    }

    get purchaseDateOptions(): Array<moment.Moment> {
        var dates = new Array<moment.Moment>();
        
        if (this.inputs.dateOfPlanEntry !== null && this.inputs.dateOfPlanEntry.isValid()) {
            var currDate = this.inputs.dateOfPlanEntry.clone().add(1, 'years').startOf('month');
        
            while(dates.length < 4) {
                if ([5, 11].includes(currDate.month() + 1) && currDate.diff(this.inputs.dateOfPlanEntry, 'months') >= 12) {
                    dates.push(currDate.clone());
                }
                currDate.add(1, 'months');
            }
        }
                
        return dates;
    }

    // bignumber does not support decimal based power operations so we will use decimal.js for this operation
    private power(n: BigNumber, m: BigNumber) : BigNumber {
        return new BigNumber(new Decimal(n.toString()).pow(new Decimal(m.toString())).toString());
    }
}