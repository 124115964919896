import { BigNumber } from 'bignumber.js';

type entry = {
    0: number;
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
    6: number;
    7: number;
    8: number;
    9: number;
    10: number;
    11: number;
    "Year/Mos": number;
};

export const getImmediateFactor = (col: number, value: number) : BigNumber => {
    const factor = table.find(obj => 
        obj["Year/Mos"] === value
    );

    if (!factor) {
        //throw new Error("getImmediateFactor lookup failed");
        return new BigNumber('0');
    }

    return new BigNumber(factor[col as keyof(entry)]);
  }

  export const getDeferredFactor = (col: number, value: number) : BigNumber => {
    const factor = deferredTable.find(obj => 
        obj["Year/Mos"] === value
    );

    if (!factor) {
      //throw new Error("getDeferredFactor lookup failed");
      return new BigNumber('0');
    }

    return new BigNumber(factor[col as keyof(entry)]);
  }

const table = [
    {
      "0": 14.39211,
      "1": 14.37999,
      "2": 14.36788,
      "3": 14.35576,
      "4": 14.34364,
      "5": 14.33152,
      "6": 14.31941,
      "7": 14.30729,
      "8": 14.29517,
      "9": 14.28305,
      "10": 14.27093,
      "11": 14.25882,
      "Year/Mos": 50
    },
    {
      "0": 14.2467,
      "1": 14.23403,
      "2": 14.22135,
      "3": 14.20868,
      "4": 14.19601,
      "5": 14.18334,
      "6": 14.17066,
      "7": 14.15799,
      "8": 14.14532,
      "9": 14.13264,
      "10": 14.11997,
      "11": 14.1073,
      "Year/Mos": 51
    },
    {
      "0": 14.09463,
      "1": 14.08137,
      "2": 14.06811,
      "3": 14.05486,
      "4": 14.0416,
      "5": 14.02835,
      "6": 14.01509,
      "7": 14.00183,
      "8": 13.98858,
      "9": 13.97532,
      "10": 13.96206,
      "11": 13.94881,
      "Year/Mos": 52
    },
    {
      "0": 13.93555,
      "1": 13.92169,
      "2": 13.90783,
      "3": 13.89397,
      "4": 13.88011,
      "5": 13.86625,
      "6": 13.85239,
      "7": 13.83853,
      "8": 13.82467,
      "9": 13.81081,
      "10": 13.79695,
      "11": 13.78308,
      "Year/Mos": 53
    },
    {
      "0": 13.76922,
      "1": 13.75475,
      "2": 13.74027,
      "3": 13.72579,
      "4": 13.71131,
      "5": 13.69683,
      "6": 13.68235,
      "7": 13.66787,
      "8": 13.6534,
      "9": 13.63892,
      "10": 13.62444,
      "11": 13.60996,
      "Year/Mos": 54
    },
    {
      "0": 13.59548,
      "1": 13.58037,
      "2": 13.56526,
      "3": 13.55014,
      "4": 13.53503,
      "5": 13.51992,
      "6": 13.50481,
      "7": 13.4897,
      "8": 13.47458,
      "9": 13.45947,
      "10": 13.44436,
      "11": 13.42925,
      "Year/Mos": 55
    },
    {
      "0": 13.41414,
      "1": 13.39837,
      "2": 13.38261,
      "3": 13.36684,
      "4": 13.35108,
      "5": 13.33531,
      "6": 13.31955,
      "7": 13.30379,
      "8": 13.28802,
      "9": 13.27226,
      "10": 13.25649,
      "11": 13.24073,
      "Year/Mos": 56
    },
    {
      "0": 13.22496,
      "1": 13.20854,
      "2": 13.19211,
      "3": 13.17568,
      "4": 13.15925,
      "5": 13.14283,
      "6": 13.1264,
      "7": 13.10997,
      "8": 13.09354,
      "9": 13.07711,
      "10": 13.06069,
      "11": 13.04426,
      "Year/Mos": 57
    },
    {
      "0": 13.02783,
      "1": 13.01073,
      "2": 12.99363,
      "3": 12.97654,
      "4": 12.95944,
      "5": 12.94234,
      "6": 12.92525,
      "7": 12.90815,
      "8": 12.89105,
      "9": 12.87395,
      "10": 12.85686,
      "11": 12.83976,
      "Year/Mos": 58
    },
    {
      "0": 12.82266,
      "1": 12.80489,
      "2": 12.78711,
      "3": 12.76934,
      "4": 12.75156,
      "5": 12.73379,
      "6": 12.71601,
      "7": 12.69823,
      "8": 12.68046,
      "9": 12.66268,
      "10": 12.64491,
      "11": 12.62713,
      "Year/Mos": 59
    },
    {
      "0": 12.60936,
      "1": 12.5909,
      "2": 12.57244,
      "3": 12.55399,
      "4": 12.53553,
      "5": 12.51707,
      "6": 12.49862,
      "7": 12.48016,
      "8": 12.46171,
      "9": 12.44325,
      "10": 12.42479,
      "11": 12.40634,
      "Year/Mos": 60
    },
    {
      "0": 12.38788,
      "1": 12.36874,
      "2": 12.34961,
      "3": 12.33047,
      "4": 12.31133,
      "5": 12.2922,
      "6": 12.27306,
      "7": 12.25393,
      "8": 12.23479,
      "9": 12.21565,
      "10": 12.19652,
      "11": 12.17738,
      "Year/Mos": 61
    },
    {
      "0": 12.15824,
      "1": 12.13843,
      "2": 12.11861,
      "3": 12.09879,
      "4": 12.07897,
      "5": 12.05915,
      "6": 12.03933,
      "7": 12.01951,
      "8": 11.9997,
      "9": 11.97988,
      "10": 11.96006,
      "11": 11.94024,
      "Year/Mos": 62
    },
    {
      "0": 11.92042,
      "1": 11.89992,
      "2": 11.87942,
      "3": 11.85892,
      "4": 11.83842,
      "5": 11.81792,
      "6": 11.79742,
      "7": 11.77693,
      "8": 11.75643,
      "9": 11.73593,
      "10": 11.71543,
      "11": 11.69493,
      "Year/Mos": 63
    },
    {
      "0": 11.67443,
      "1": 11.65326,
      "2": 11.63209,
      "3": 11.61092,
      "4": 11.58975,
      "5": 11.56859,
      "6": 11.54742,
      "7": 11.52625,
      "8": 11.50508,
      "9": 11.48391,
      "10": 11.46274,
      "11": 11.44158,
      "Year/Mos": 64
    },
    {
      "0": 11.42041,
      "1": 11.39859,
      "2": 11.37676,
      "3": 11.35494,
      "4": 11.33312,
      "5": 11.3113,
      "6": 11.28947,
      "7": 11.26765,
      "8": 11.24583,
      "9": 11.22401,
      "10": 11.20219,
      "11": 11.18036,
      "Year/Mos": 65
    },
    {
      "0": 11.15854,
      "1": 11.13608,
      "2": 11.11362,
      "3": 11.09116,
      "4": 11.0687,
      "5": 11.04624,
      "6": 11.02378,
      "7": 11.00132,
      "8": 10.97886,
      "9": 10.9564,
      "10": 10.93394,
      "11": 10.91148,
      "Year/Mos": 66
    },
    {
      "0": 10.88902,
      "1": 10.86594,
      "2": 10.84287,
      "3": 10.8198,
      "4": 10.79672,
      "5": 10.77365,
      "6": 10.75057,
      "7": 10.7275,
      "8": 10.70442,
      "9": 10.68135,
      "10": 10.65828,
      "11": 10.6352,
      "Year/Mos": 67
    },
    {
      "0": 10.61213,
      "1": 10.58846,
      "2": 10.5648,
      "3": 10.54113,
      "4": 10.51747,
      "5": 10.49381,
      "6": 10.47014,
      "7": 10.44648,
      "8": 10.42281,
      "9": 10.39915,
      "10": 10.37548,
      "11": 10.35182,
      "Year/Mos": 68
    },
    {
      "0": 10.32815,
      "1": 10.30394,
      "2": 10.27972,
      "3": 10.2555,
      "4": 10.23128,
      "5": 10.20706,
      "6": 10.18284,
      "7": 10.15862,
      "8": 10.13441,
      "9": 10.11019,
      "10": 10.08597,
      "11": 10.06175,
      "Year/Mos": 69
    },
    {
      "0": 10.03753,
      "1": 10.0128,
      "2": 9.98807,
      "3": 9.96333,
      "4": 9.9386,
      "5": 9.91387,
      "6": 9.88913,
      "7": 9.8644,
      "8": 9.83967,
      "9": 9.81494,
      "10": 9.7902,
      "11": 9.76547,
      "Year/Mos": 70
    },
    {
      "0": 9.74074,
      "1": 9.71554,
      "2": 9.69034,
      "3": 9.66515,
      "4": 9.63995,
      "5": 9.61475,
      "6": 9.58955,
      "7": 9.56436,
      "8": 9.53916,
      "9": 9.51396,
      "10": 9.48877,
      "11": 9.46357,
      "Year/Mos": 71
    },
    {
      "0": 9.43837,
      "1": 9.41275,
      "2": 9.38713,
      "3": 9.36151,
      "4": 9.33589,
      "5": 9.31027,
      "6": 9.28466,
      "7": 9.25904,
      "8": 9.23342,
      "9": 9.2078,
      "10": 9.18218,
      "11": 9.15656,
      "Year/Mos": 72
    },
    {
      "0": 9.13094,
      "1": 9.10495,
      "2": 9.07897,
      "3": 9.05298,
      "4": 9.02699,
      "5": 9.00101,
      "6": 8.97502,
      "7": 8.94903,
      "8": 8.92305,
      "9": 8.89706,
      "10": 8.87107,
      "11": 8.84509,
      "Year/Mos": 73
    },
    {
      "0": 8.8191,
      "1": 8.79281,
      "2": 8.76652,
      "3": 8.74023,
      "4": 8.71394,
      "5": 8.68765,
      "6": 8.66136,
      "7": 8.63507,
      "8": 8.60878,
      "9": 8.58249,
      "10": 8.5562,
      "11": 8.52991,
      "Year/Mos": 74
    },
    {
      "0": 8.50362,
      "1": 8.47708,
      "2": 8.45054,
      "3": 8.42401,
      "4": 8.39747,
      "5": 8.37093,
      "6": 8.3444,
      "7": 8.31786,
      "8": 8.29132,
      "9": 8.26479,
      "10": 8.23825,
      "11": 8.21171,
      "Year/Mos": 75
    },
    {
      "0": 8.18518,
      "1": 8.15847,
      "2": 8.13176,
      "3": 8.10505,
      "4": 8.07834,
      "5": 8.05163,
      "6": 8.02492,
      "7": 7.99821,
      "8": 7.9715,
      "9": 7.94479,
      "10": 7.91808,
      "11": 7.89137,
      "Year/Mos": 76
    },
    {
      "0": 7.86466,
      "1": 7.83785,
      "2": 7.81104,
      "3": 7.78423,
      "4": 7.75742,
      "5": 7.73061,
      "6": 7.7038,
      "7": 7.67699,
      "8": 7.65019,
      "9": 7.62338,
      "10": 7.59657,
      "11": 7.56976,
      "Year/Mos": 77
    },
    {
      "0": 7.54295,
      "1": 7.51613,
      "2": 7.48931,
      "3": 7.46249,
      "4": 7.43567,
      "5": 7.40884,
      "6": 7.38202,
      "7": 7.3552,
      "8": 7.32838,
      "9": 7.30156,
      "10": 7.27474,
      "11": 7.24792,
      "Year/Mos": 78
    },
    {
      "0": 7.2211,
      "1": 7.19436,
      "2": 7.16762,
      "3": 7.14088,
      "4": 7.11414,
      "5": 7.0874,
      "6": 7.06066,
      "7": 7.03392,
      "8": 7.00719,
      "9": 6.98045,
      "10": 6.95371,
      "11": 6.92697,
      "Year/Mos": 79
    },
    {
      "0": 6.90023,
      "1": "",
      "2": "",
      "3": "",
      "4": "",
      "5": "",
      "6": "",
      "7": "",
      "8": "",
      "9": "",
      "10": "",
      "11": "",
      "Year/Mos": 80
    }
  ] as entry[];

  const deferredTable = [
    {
      "0": 5204010.186,
      "1": 5178766.98,
      "2": 5153523.78,
      "3": 5128280.57,
      "4": 5103037.37,
      "5": 5077794.16,
      "6": 5052550.96,
      "7": 5027307.75,
      "8": 5002064.55,
      "9": 4976821.34,
      "10": 4951578.14,
      "11": 4926334.94,
      "Year/Mos": 20
    },
    {
      "0": 4901091.73,
      "1": 4877284.91,
      "2": 4853478.08,
      "3": 4829671.25,
      "4": 4805864.43,
      "5": 4782057.6,
      "6": 4758250.78,
      "7": 4734443.95,
      "8": 4710637.13,
      "9": 4686830.3,
      "10": 4663023.48,
      "11": 4639216.65,
      "Year/Mos": 21
    },
    {
      "0": 4615409.826,
      "1": 4592957.57,
      "2": 4570505.32,
      "3": 4548053.06,
      "4": 4525600.8,
      "5": 4503148.55,
      "6": 4480696.29,
      "7": 4458244.04,
      "8": 4435791.78,
      "9": 4413339.53,
      "10": 4390887.27,
      "11": 4368435.02,
      "Year/Mos": 22
    },
    {
      "0": 4345982.763,
      "1": 4324807.84,
      "2": 4303632.92,
      "3": 4282458,
      "4": 4261283.08,
      "5": 4240108.16,
      "6": 4218933.24,
      "7": 4197758.32,
      "8": 4176583.4,
      "9": 4155408.47,
      "10": 4134233.55,
      "11": 4113058.63,
      "Year/Mos": 23
    },
    {
      "0": 4091883.711,
      "1": 4071913.3,
      "2": 4051942.89,
      "3": 4031972.48,
      "4": 4012002.07,
      "5": 3992031.66,
      "6": 3972061.25,
      "7": 3952090.84,
      "8": 3932120.43,
      "9": 3912150.02,
      "10": 3892179.62,
      "11": 3872209.21,
      "Year/Mos": 24
    },
    {
      "0": 3852238.796,
      "1": 3833404.24,
      "2": 3814569.67,
      "3": 3795735.11,
      "4": 3776900.55,
      "5": 3758065.99,
      "6": 3739231.43,
      "7": 3720396.87,
      "8": 3701562.31,
      "9": 3682727.75,
      "10": 3663893.19,
      "11": 3645058.63,
      "Year/Mos": 25
    },
    {
      "0": 3626224.069,
      "1": 3608460.74,
      "2": 3590697.41,
      "3": 3572934.08,
      "4": 3555170.75,
      "5": 3537407.42,
      "6": 3519644.09,
      "7": 3501880.76,
      "8": 3484117.43,
      "9": 3466354.1,
      "10": 3448590.77,
      "11": 3430827.44,
      "Year/Mos": 26
    },
    {
      "0": 3413064.108,
      "1": 3396311.21,
      "2": 3379558.31,
      "3": 3362805.41,
      "4": 3346052.52,
      "5": 3329299.62,
      "6": 3312546.72,
      "7": 3295793.82,
      "8": 3279040.93,
      "9": 3262288.03,
      "10": 3245535.13,
      "11": 3228782.23,
      "Year/Mos": 27
    },
    {
      "0": 3212029.335,
      "1": 3196229.52,
      "2": 3180429.7,
      "3": 3164629.88,
      "4": 3148830.05,
      "5": 3133030.23,
      "6": 3117230.41,
      "7": 3101430.59,
      "8": 3085630.77,
      "9": 3069830.95,
      "10": 3054031.13,
      "11": 3038231.31,
      "Year/Mos": 28
    },
    {
      "0": 3022431.494,
      "1": 3007530.65,
      "2": 2992629.8,
      "3": 2977728.95,
      "4": 2962828.1,
      "5": 2947927.25,
      "6": 2933026.4,
      "7": 2918125.55,
      "8": 2903224.71,
      "9": 2888323.86,
      "10": 2873423.01,
      "11": 2858522.16,
      "Year/Mos": 29
    },
    {
      "0": 2843621.312,
      "1": 2829568.35,
      "2": 2815515.38,
      "3": 2801462.41,
      "4": 2787409.45,
      "5": 2773356.48,
      "6": 2759303.52,
      "7": 2745250.55,
      "8": 2731197.58,
      "9": 2717144.62,
      "10": 2703091.65,
      "11": 2689038.68,
      "Year/Mos": 30
    },
    {
      "0": 2674985.719,
      "1": 2661732.43,
      "2": 2648479.15,
      "3": 2635225.87,
      "4": 2621972.58,
      "5": 2608719.3,
      "6": 2595466.01,
      "7": 2582212.73,
      "8": 2568959.44,
      "9": 2555706.16,
      "10": 2542452.88,
      "11": 2529199.59,
      "Year/Mos": 31
    },
    {
      "0": 2515946.308,
      "1": 2503447.3,
      "2": 2490948.28,
      "3": 2478449.27,
      "4": 2465950.26,
      "5": 2453451.25,
      "6": 2440952.24,
      "7": 2428453.22,
      "8": 2415954.21,
      "9": 2403455.2,
      "10": 2390956.19,
      "11": 2378457.18,
      "Year/Mos": 32
    },
    {
      "0": 2365958.165,
      "1": 2354170.59,
      "2": 2342383.01,
      "3": 2330595.44,
      "4": 2318807.86,
      "5": 2307020.29,
      "6": 2295232.71,
      "7": 2283445.13,
      "8": 2271657.56,
      "9": 2259869.98,
      "10": 2248082.41,
      "11": 2236294.83,
      "Year/Mos": 33
    },
    {
      "0": 2224507.253,
      "1": 2213390.7,
      "2": 2202274.16,
      "3": 2191157.61,
      "4": 2180041.06,
      "5": 2168924.51,
      "6": 2157807.96,
      "7": 2146691.41,
      "8": 2135574.86,
      "9": 2124458.31,
      "10": 2113341.76,
      "11": 2102225.22,
      "Year/Mos": 34
    },
    {
      "0": 2091108.667,
      "1": 2080625.06,
      "2": 2070141.46,
      "3": 2059657.85,
      "4": 2049174.25,
      "5": 2038690.64,
      "6": 2028207.04,
      "7": 2017723.43,
      "8": 2007239.83,
      "9": 1996756.23,
      "10": 1986272.62,
      "11": 1975789.02,
      "Year/Mos": 35
    },
    {
      "0": 1965305.412,
      "1": 1955418.87,
      "2": 1945532.32,
      "3": 1935645.77,
      "4": 1925759.23,
      "5": 1915872.68,
      "6": 1905986.14,
      "7": 1896099.59,
      "8": 1886213.05,
      "9": 1876326.5,
      "10": 1866439.95,
      "11": 1856553.41,
      "Year/Mos": 36
    },
    {
      "0": 1846666.862,
      "1": 1837343.48,
      "2": 1828020.1,
      "3": 1818696.72,
      "4": 1809373.35,
      "5": 1800049.97,
      "6": 1790726.59,
      "7": 1781403.21,
      "8": 1772079.83,
      "9": 1762756.45,
      "10": 1753433.07,
      "11": 1744109.69,
      "Year/Mos": 37
    },
    {
      "0": 1734786.314,
      "1": 1725994.15,
      "2": 1717201.99,
      "3": 1708409.82,
      "4": 1699617.66,
      "5": 1690825.49,
      "6": 1682033.33,
      "7": 1673241.17,
      "8": 1664449,
      "9": 1655656.84,
      "10": 1646864.68,
      "11": 1638072.51,
      "Year/Mos": 38
    },
    {
      "0": 1629280.348,
      "1": 1620989.32,
      "2": 1612698.3,
      "3": 1604407.28,
      "4": 1596116.25,
      "5": 1587825.23,
      "6": 1579534.2,
      "7": 1571243.18,
      "8": 1562952.16,
      "9": 1554661.13,
      "10": 1546370.11,
      "11": 1538079.08,
      "Year/Mos": 39
    },
    {
      "0": 1529788.06,
      "1": 1521969.79,
      "2": 1514151.52,
      "3": 1506333.26,
      "4": 1498514.99,
      "5": 1490696.72,
      "6": 1482878.45,
      "7": 1475060.19,
      "8": 1467241.92,
      "9": 1459423.65,
      "10": 1451605.38,
      "11": 1443787.12,
      "Year/Mos": 40
    },
    {
      "0": 1435968.848,
      "1": 1428596.55,
      "2": 1421224.25,
      "3": 1413851.96,
      "4": 1406479.66,
      "5": 1399107.36,
      "6": 1391735.06,
      "7": 1384362.77,
      "8": 1376990.47,
      "9": 1369618.17,
      "10": 1362245.87,
      "11": 1354873.58,
      "Year/Mos": 41
    },
    {
      "0": 1347501.277,
      "1": 1340549.72,
      "2": 1333598.16,
      "3": 1326646.6,
      "4": 1319695.04,
      "5": 1312743.49,
      "6": 1305791.93,
      "7": 1298840.37,
      "8": 1291888.81,
      "9": 1284937.25,
      "10": 1277985.69,
      "11": 1271034.14,
      "Year/Mos": 42
    },
    {
      "0": 1264082.577,
      "1": 1257527.98,
      "2": 1250973.38,
      "3": 1244418.77,
      "4": 1237864.17,
      "5": 1231309.57,
      "6": 1224754.97,
      "7": 1218200.37,
      "8": 1211645.77,
      "9": 1205091.17,
      "10": 1198536.57,
      "11": 1191981.97,
      "Year/Mos": 43
    },
    {
      "0": 1185427.367,
      "1": 1179247.27,
      "2": 1173067.17,
      "3": 1166887.07,
      "4": 1160706.97,
      "5": 1154526.87,
      "6": 1148346.77,
      "7": 1142166.67,
      "8": 1135986.57,
      "9": 1129806.47,
      "10": 1123626.37,
      "11": 1117446.28,
      "Year/Mos": 44
    },
    {
      "0": 1111266.176,
      "1": 1105439.43,
      "2": 1099612.67,
      "3": 1093785.92,
      "4": 1087959.17,
      "5": 1082132.42,
      "6": 1076305.67,
      "7": 1070478.92,
      "8": 1064652.17,
      "9": 1058825.42,
      "10": 1052998.67,
      "11": 1047171.92,
      "Year/Mos": 45
    },
    {
      "0": 1041345.168,
      "1": 1035851.82,
      "2": 1030358.47,
      "3": 1024865.12,
      "4": 1019371.76,
      "5": 1013878.41,
      "6": 1008385.06,
      "7": 1002891.71,
      "8": 997398.36,
      "9": 991905.01,
      "10": 986411.66,
      "11": 980918.31,
      "Year/Mos": 46
    },
    {
      "0": 975424.9564,
      "1": 970246.19,
      "2": 965067.41,
      "3": 959888.64,
      "4": 954709.87,
      "5": 949531.1,
      "6": 944352.33,
      "7": 939173.56,
      "8": 933994.79,
      "9": 928816.02,
      "10": 923637.25,
      "11": 918458.48,
      "Year/Mos": 47
    },
    {
      "0": 913279.7066,
      "1": 908397.76,
      "2": 903515.82,
      "3": 898633.88,
      "4": 893751.94,
      "5": 888870,
      "6": 883988.05,
      "7": 879106.11,
      "8": 874224.17,
      "9": 869342.23,
      "10": 864460.29,
      "11": 859578.35,
      "Year/Mos": 48
    },
    {
      "0": 854696.4031,
      "1": 850094.56,
      "2": 845492.71,
      "3": 840890.86,
      "4": 836289.02,
      "5": 831687.17,
      "6": 827085.32,
      "7": 822483.48,
      "8": 817881.63,
      "9": 813279.78,
      "10": 808677.94,
      "11": 804076.09,
      "Year/Mos": 49
    },
    {
      "0": 799474.2447,
      "1": 795136.72,
      "2": 790799.2,
      "3": 786461.68,
      "4": 782124.16,
      "5": 777786.64,
      "6": 773449.12,
      "7": 769111.6,
      "8": 764774.08,
      "9": 760436.56,
      "10": 756099.04,
      "11": 751761.52,
      "Year/Mos": 50
    },
    {
      "0": 747423.9999,
      "1": 743335.91,
      "2": 739247.81,
      "3": 735159.72,
      "4": 731071.63,
      "5": 726983.53,
      "6": 722895.44,
      "7": 718807.35,
      "8": 714719.25,
      "9": 710631.16,
      "10": 706543.07,
      "11": 702454.97,
      "Year/Mos": 51
    },
    {
      "0": 698366.88,
      "1": 694514.16,
      "2": 690661.45,
      "3": 686808.73,
      "4": 682956.02,
      "5": 679103.3,
      "6": 675250.59,
      "7": 671397.87,
      "8": 667545.15,
      "9": 663692.44,
      "10": 659839.72,
      "11": 655987.01,
      "Year/Mos": 52
    },
    {
      "0": 652134.2902,
      "1": 648503.71,
      "2": 644873.12,
      "3": 641242.54,
      "4": 637611.96,
      "5": 633981.38,
      "6": 630350.79,
      "7": 626720.21,
      "8": 623089.63,
      "9": 619459.05,
      "10": 615828.46,
      "11": 612197.88,
      "Year/Mos": 53
    },
    {
      "0": 608567.2988,
      "1": 605146.33,
      "2": 601725.37,
      "3": 598304.4,
      "4": 594883.43,
      "5": 591462.47,
      "6": 588041.5,
      "7": 584620.53,
      "8": 581199.57,
      "9": 577778.6,
      "10": 574357.63,
      "11": 570936.67,
      "Year/Mos": 54
    },
    {
      "0": 567515.6986,
      "1": 564292.54,
      "2": 561069.38,
      "3": 557846.23,
      "4": 554623.07,
      "5": 551399.91,
      "6": 548176.75,
      "7": 544953.59,
      "8": 541730.44,
      "9": 538507.28,
      "10": 535284.12,
      "11": 532060.96,
      "Year/Mos": 55
    },
    {
      "0": 528837.8056,
      "1": 525801.34,
      "2": 522764.88,
      "3": 519728.42,
      "4": 516691.96,
      "5": 513655.5,
      "6": 510619.04,
      "7": 507582.58,
      "8": 504546.11,
      "9": 501509.65,
      "10": 498473.19,
      "11": 495436.73,
      "Year/Mos": 56
    },
    {
      "0": 492400.268,
      "1": 489540.02,
      "2": 486679.77,
      "3": 483819.52,
      "4": 480959.27,
      "5": 478099.03,
      "6": 475238.78,
      "7": 472378.53,
      "8": 469518.28,
      "9": 466658.03,
      "10": 463797.78,
      "11": 460937.54,
      "Year/Mos": 57
    },
    {
      "0": 458077.2871,
      "1": 455383.36,
      "2": 452689.44,
      "3": 449995.52,
      "4": 447301.6,
      "5": 444607.68,
      "6": 441913.75,
      "7": 439219.83,
      "8": 436525.91,
      "9": 433831.99,
      "10": 431138.07,
      "11": 428444.14,
      "Year/Mos": 58
    },
    {
      "0": 425750.222,
      "1": 423213.32,
      "2": 420676.43,
      "3": 418139.53,
      "4": 415602.63,
      "5": 413065.73,
      "6": 410528.83,
      "7": 407991.93,
      "8": 405455.04,
      "9": 402918.14,
      "10": 400381.24,
      "11": 397844.34,
      "Year/Mos": 59
    },
    {
      "0": 395307.4434,
      "1": 392918.79,
      "2": 390530.14,
      "3": 388141.49,
      "4": 385752.84,
      "5": 383364.19,
      "6": 380975.54,
      "7": 378586.89,
      "8": 376198.24,
      "9": 373809.59,
      "10": 371420.94,
      "11": 369032.29,
      "Year/Mos": 60
    },
    {
      "0": 366643.6407,
      "1": 364394.96,
      "2": 362146.28,
      "3": 359897.61,
      "4": 357648.93,
      "5": 355400.25,
      "6": 353151.57,
      "7": 350902.9,
      "8": 348654.22,
      "9": 346405.54,
      "10": 344156.86,
      "11": 341908.18,
      "Year/Mos": 61
    },
    {
      "0": 339659.506,
      "1": 337543.01,
      "2": 335426.52,
      "3": 333310.02,
      "4": 331193.53,
      "5": 329077.04,
      "6": 326960.54,
      "7": 324844.05,
      "8": 322727.55,
      "9": 320611.06,
      "10": 318494.57,
      "11": 316378.07,
      "Year/Mos": 62
    },
    {
      "0": 314261.5777,
      "1": 312269.93,
      "2": 310278.27,
      "3": 308286.62,
      "4": 306294.97,
      "5": 304303.32,
      "6": 302311.67,
      "7": 300320.02,
      "8": 298328.37,
      "9": 296336.71,
      "10": 294345.06,
      "11": 292353.41,
      "Year/Mos": 63
    },
    {
      "0": 290361.759,
      "1": 288488.02,
      "2": 286614.28,
      "3": 284740.54,
      "4": 282866.8,
      "5": 280993.06,
      "6": 279119.32,
      "7": 277245.57,
      "8": 275371.83,
      "9": 273498.09,
      "10": 271624.35,
      "11": 269750.61,
      "Year/Mos": 64
    },
    {
      "0": 267876.8717,
      "1": 266114.51,
      "2": 264352.14,
      "3": 262589.78,
      "4": 260827.41,
      "5": 259065.05,
      "6": 257302.69,
      "7": 255540.32,
      "8": 253777.96,
      "9": 252015.59,
      "10": 250253.23,
      "11": 248490.87,
      "Year/Mos": 65
    },
    {
      "0": 246728.5012,
      "1": 245071.45,
      "2": 243414.41,
      "3": 241757.36,
      "4": 240100.31,
      "5": 238443.27,
      "6": 236786.22,
      "7": 235129.18,
      "8": 233472.13,
      "9": 231815.08,
      "10": 230158.04,
      "11": 228500.99,
      "Year/Mos": 66
    },
    {
      "0": 226843.9424,
      "1": 225286.61,
      "2": 223729.29,
      "3": 222171.96,
      "4": 220614.63,
      "5": 219057.3,
      "6": 217499.97,
      "7": 215942.64,
      "8": 214385.31,
      "9": 212827.98,
      "10": 211270.66,
      "11": 209713.33,
      "Year/Mos": 67
    },
    {
      "0": 208155.9987,
      "1": 206693.13,
      "2": 205230.26,
      "3": 203767.38,
      "4": 202304.51,
      "5": 200841.64,
      "6": 199378.77,
      "7": 197915.9,
      "8": 196453.03,
      "9": 194990.16,
      "10": 193527.28,
      "11": 192064.41,
      "Year/Mos": 68
    },
    {
      "0": 190601.542,
      "1": 189228.19,
      "2": 187854.84,
      "3": 186481.49,
      "4": 185108.14,
      "5": 183734.79,
      "6": 182361.43,
      "7": 180988.08,
      "8": 179614.73,
      "9": 178241.38,
      "10": 176868.03,
      "11": 175494.68,
      "Year/Mos": 69
    },
    {
      "0": 174121.327,
      "1": 172832.85,
      "2": 171544.38,
      "3": 170255.91,
      "4": 168967.44,
      "5": 167678.96,
      "6": 166390.49,
      "7": 165102.02,
      "8": 163813.55,
      "9": 162525.07,
      "10": 161236.6,
      "11": 159948.13,
      "Year/Mos": 70
    },
    {
      "0": 158659.6553,
      "1": 157451.71,
      "2": 156243.76,
      "3": 155035.81,
      "4": 153827.86,
      "5": 152619.92,
      "6": 151411.97,
      "7": 150204.02,
      "8": 148996.07,
      "9": 147788.12,
      "10": 146580.18,
      "11": 145372.23,
      "Year/Mos": 71
    },
    {
      "0": 144164.2804,
      "1": 143032.77,
      "2": 141901.27,
      "3": 140769.76,
      "4": 139638.25,
      "5": 138506.75,
      "6": 137375.24,
      "7": 136243.74,
      "8": 135112.23,
      "9": 133980.72,
      "10": 132849.22,
      "11": 131717.71,
      "Year/Mos": 72
    },
    {
      "0": 130586.2032,
      "1": 129527.31,
      "2": 128468.41,
      "3": 127409.52,
      "4": 126350.62,
      "5": 125291.73,
      "6": 124232.84,
      "7": 123173.94,
      "8": 122115.05,
      "9": 121056.15,
      "10": 119997.26,
      "11": 118938.36,
      "Year/Mos": 73
    },
    {
      "0": 117879.4686,
      "1": 116889.59,
      "2": 115899.71,
      "3": 114909.84,
      "4": 113919.96,
      "5": 112930.08,
      "6": 111940.21,
      "7": 110950.33,
      "8": 109960.45,
      "9": 108970.57,
      "10": 107980.7,
      "11": 106990.82,
      "Year/Mos": 74
    },
    {
      "0": 106000.9429,
      "1": 105076.72,
      "2": 104152.49,
      "3": 103228.26,
      "4": 102304.03,
      "5": 101379.81,
      "6": 100455.58,
      "7": 99531.35,
      "8": 98607.12,
      "9": 97682.89,
      "10": 96758.67,
      "11": 95834.44,
      "Year/Mos": 75
    },
    {
      "0": 94910.21223,
      "1": 94048.48,
      "2": 93186.74,
      "3": 92325.01,
      "4": 91463.27,
      "5": 90601.54,
      "6": 89739.8,
      "7": 88878.06,
      "8": 88016.33,
      "9": 87154.59,
      "10": 86292.86,
      "11": 85431.12,
      "Year/Mos": 76
    },
    {
      "0": 84569.38742,
      "1": 83767.18,
      "2": 82964.97,
      "3": 82162.76,
      "4": 81360.56,
      "5": 80558.35,
      "6": 79756.14,
      "7": 78953.93,
      "8": 78151.72,
      "9": 77349.52,
      "10": 76547.31,
      "11": 75745.1,
      "Year/Mos": 77
    },
    {
      "0": 74942.89206,
      "1": 74197.43,
      "2": 73451.97,
      "3": 72706.51,
      "4": 71961.05,
      "5": 71215.59,
      "6": 70470.13,
      "7": 69724.67,
      "8": 68979.21,
      "9": 68233.75,
      "10": 67488.29,
      "11": 66742.83,
      "Year/Mos": 78
    },
    {
      "0": 65997.3735,
      "1": 65306.05,
      "2": 64614.73,
      "3": 63923.41,
      "4": 63232.09,
      "5": 62540.77,
      "6": 61849.45,
      "7": 61158.12,
      "8": 60466.8,
      "9": 59775.48,
      "10": 59084.16,
      "11": 58392.84,
      "Year/Mos": 79
    },
    {
      "0": 57701.51681,
      "1": 57061.88,
      "2": 56422.24,
      "3": 55782.6,
      "4": 55142.96,
      "5": 54503.32,
      "6": 53863.68,
      "7": 53224.04,
      "8": 52584.4,
      "9": 51944.76,
      "10": 51305.12,
      "11": 50665.48,
      "Year/Mos": 80
    },
    {
      "0": 50025.83893,
      "1": "",
      "2": "",
      "3": "",
      "4": "",
      "5": "",
      "6": "",
      "7": "",
      "8": "",
      "9": "",
      "10": "",
      "11": "",
      "Year/Mos": 81
    }
  ] as entry[];

