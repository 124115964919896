import React from "react";
import { AppBar, Toolbar, makeStyles, Box } from "@material-ui/core";
import { NONAME } from "dns";

const useStyles = makeStyles({
    root: {
        backgroundColor: "#383232",
        backgroundImage: "url('./bodyBackground.jpg')",
        backgroundRepeat: "repeat-x",
        top: "auto",
        bottom: 0
    }
});

const Footer: React.FC<{ dateOfServicePurchase: string }> = ({ dateOfServicePurchase }) => {
    const styles = useStyles();
    return (
        <AppBar position="fixed" className={styles.root}>
            <Toolbar style={{ fontWeight: "bold", marginBottom: "2.2rem", fontSize: ".8rem" }}>
                <Box style={{paddingTop: ".5rem"}}>
                The Estimated Cost of Service Purchase amount shown above is an ESTIMATE of the cost and is for illustration purposes only.&nbsp;
                <span className={dateOfServicePurchase === '' ? 'hidden' : ''}>It assumes the service is purchased on {dateOfServicePurchase}. </span>
                Your actual Cost of Service Purchase will be based on your age, annual base salary, the months of service you purchase and shall be subject to any administrative rules and Plan provisions governing the Cost of Service Purchases on the date you purchase service. The Plan has no liability or obligation, either legal or equitable, to accept payment for a Service Purchase as a result of using this calculator. This ESTIMATE is not to be construed in any way as a promise or contract with the Plan to accept payment of a service credit purchase. The Final Cost of Service Purchase in all cases will be determined in accordance with the Plan and laws in effect at the time of the purchase. The Service Purchase provisions in the Plan are subject to IRS approval and Service Purchase will not be available if rejected by the IRS.
                </Box>
            </Toolbar>
        </AppBar>)
};

export { Footer };